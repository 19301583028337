import React, { useEffect, useState } from "react";
import InputBox from "../InputBox/InputBox";
import Button from "../Button/Button";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Firebase/firebase.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddAddress({ user }) {
  const [loader, setLoader] = useState(false);
  const [postOffice, setPostOffice] = useState();
  const addressCollectionRef = collection(db, user?.district);
  const addressInitialState = {
    name: "",
    houseName: "",
    streetName: "",
    pincode: "",
    postOffice: "",
    mobileNumber: "",
    areaCommittee: user?.name,
  };
  const [addressDetails, setaddressDetails] = useState(addressInitialState);

  useEffect(() => {
    if (addressDetails?.pincode?.length === 6) {
      fetchPostOffice(addressDetails?.pincode);
    }
  }, [addressDetails?.pincode]);

  const fetchPostOffice = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      const data = await response.json();
      setPostOffice(data[0]?.PostOffice);
    } catch (error) {
      console.error("Error fetching post office:", error);
    }
  };

  const addAddressBtnClick = async () => {
    try {
      if (
        addressDetails?.name &&
        addressDetails?.houseName &&
        addressDetails?.streetName &&
        addressDetails?.pincode &&
        addressDetails?.postOffice &&
        addressDetails?.mobileNumber
      ) {
        setLoader(true);
        addDoc(addressCollectionRef, addressDetails).then((resp) => {
          if (resp?.id) {
            setLoader(false);
            toast.success(`${addressDetails?.name} Added Succesfully`);
            setaddressDetails(addressInitialState);

            console.log(resp);
          } 
        });
      }
      else {
        toast.error("All fields are mandatory");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <h1 className="text-left text-2xl text-[#464A57] font-bold mb-10">
        Add New Address
      </h1>
      <div className="flex flex-wrap gap-5 items-center">
        <div className="w-full sm:max-w-[48%] md:max-w-[30%] px-2">
          <InputBox
            value={addressDetails?.name}
            onChange={(e) => {
              setaddressDetails({ ...addressDetails, name: e.target.value });
            }}
            label="Name"
            type="name"
            id="name"
          />
        </div>
       
        <div className="w-full sm:max-w-[48%] md:max-w-[30%] px-2">
          <InputBox
            value={addressDetails?.houseName}
            onChange={(e) => {
              setaddressDetails({
                ...addressDetails,
                houseName: e.target.value,
              });
            }}
            label="House Name"
            type="name"
            id="HouseName"
          />
        </div>
        <div className="w-full sm:max-w-[48%] md:max-w-[30%] px-2">
          <InputBox
            value={addressDetails?.streetName}
            onChange={(e) => {
              setaddressDetails({
                ...addressDetails,
                streetName: e.target.value,
              });
            }}
            label="Street Name"
            type="name"
            id="StreetName"
          />
        </div>
        <div className="w-full sm:max-w-[48%] md:max-w-[30%] px-2">
          <InputBox
            value={addressDetails?.pincode}
            onChange={(e) => {
              setaddressDetails({ ...addressDetails, pincode: e.target.value });
            }}
            label="Pincode"
            type="number"
            id="pincode"
          />
        </div>
        <div
          className={`${
            postOffice?.length > 0 ? "" : "opacity-50"
          } w-full sm:max-w-[48%] md:max-w-[30%] px-2`}
        >
          <label className="block text-sm font-semibold leading-6 text-gray-900">
            Post Office
          </label>
          <select
            value={addressDetails?.postOffice}
            onChange={(e) => {
              setaddressDetails({
                ...addressDetails,
                postOffice: e.target.value,
              });
            }}
            id="postoffice"
            className={` w-full mt-2 sm:max-w-80 appearance-none text-slate-900 bg-white rounded-md px-3 h-10 shadow-sm sm:text-sm focus:outline-none  focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 cursor-pointer`}
          >
            <option value="" hidden>
              Select Postoffice
            </option>
            {postOffice?.map((postOfficeName, index) => (
              <option key={index} value={postOfficeName?.Name}>
                {postOfficeName?.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full sm:max-w-[48%] md:max-w-[30%] px-2">
          <InputBox
            value={addressDetails?.mobileNumber}
            onChange={(e) => {
              setaddressDetails({
                ...addressDetails,
                mobileNumber: e.target.value,
              });
            }}
            label="Mobile Number"
            type="number"
            id="mobile"
          />
        </div>
      </div>
      <div className="w-full md:max-w-[30%] px-2 mt-8">
        <Button
          type="submit"
          loader={loader}
          label="Add Address"
          size="sm"
          onClick={addAddressBtnClick}
        />
      </div>
    </>
  );
}

export default AddAddress;
