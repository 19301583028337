import {
  ArchiveIcon,
  CheckIcon,
  MinusSmIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
import clsx from "clsx";
import moment from "moment";
import { useEffect } from "react";
import * as React from "react";
import ReactPaginate from "react-paginate";

export const Table = ({
  data,
  currentPage,
  columns,
  handlePageChange,
  totalPages,
  align,
  sort,
  sortField,
  sortOrder,
  rowNonClickable,
  userType
}) => {
  const nonsortables = ["slnoindex", "slno"];

  if (!data?.length) {
    return (
      <div className="flex flex-col items-center justify-center text-gray-500 bg-white h-80">
        <ArchiveIcon className="w-16 h-16" />
        <h4>No Entries Found</h4>
      </div>
    );
  }

  const format = (num) =>
    Number(num).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  return (
    <div className="flex flex-col w-full overflow-x-auto -my-2">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg sm:rounded-bl-none sm:rounded-br-none">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-[#1f3a8a]">
                <tr>
                  
                  {columns.map((column, index) => (
                    <th
                      onClick={() => {
                        if (!nonsortables.includes(column.field)) {
                          sort(column.field);
                        }
                      }}
                      key={column.title + index}
                      scope="col"
                      className={`px-6 py-6 cursor-pointer font-poppins text-sm font-bold tracking-wider text-left text-white  ${
                        (column.title.includes("Sr No") ||
                          column.title.includes("Actions") ||
                          column.title.includes("Cost")) &&
                        "text-center"
                      } ${userType === "Admin" &&
                          column.title.includes("Actions") &&
                        "hidden"
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <div
                          className={`${
                            index === 0 || align === "left"
                              ? "text-left"
                              : "text-left"
                          } grow ${
                            (column.title.includes("Sr No") ||
                              column.title.includes("Actions") ||
                              column.title.includes("Cost")) &&
                            "text-center"
                          }`}
                        >
                          {column.title}
                        </div>
                        {sortField === column.field && sortOrder === "asc" && (
                          <div>
                            <SortAscendingIcon className="w-5 h-5 color-white" />
                          </div>
                        )}
                        {sortField === column.field && sortOrder === "desc" && (
                          <div>
                            <SortDescendingIcon className="w-5 h-5 color-white" />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((entry, entryIndex) => (
                  <tr
                    key={entry?.id || entryIndex}
                    className={clsx(
                      "bg-white bg-opacity-50 border-b border-l border-r border-slate-100 last-child:border-b-0",
                      !rowNonClickable && "cursor-pointer"
                    )}
                  >
                    
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className={`px-6 py-4  text-sm font-semibold leading-6 text-gray-900 ${
                          title.includes("Sr No") ||
                          title.includes("Price") ||
                          title.includes("Cost")
                            ? "text-center"
                            : ""
                        } ${
                          !title.includes("Address") ? "whitespace-nowrap" : ""
                        } ${userType === "Admin" &&
                          title.includes("Actions") &&
                        "hidden"
                      }`}
                      >
                        {field === "slno" ? (
                          entryIndex + 1
                        ) : field === "slnoindex" ? (
                          (currentPage || 0) * 10 + (entryIndex + 1)
                        ) : Cell ? (
                          <Cell entry={entry} />
                        ) : entry[field] !== null ? (
                          title.includes("Date") ? (
                            entry[field] ? (
                              moment(entry[field]).format("MM/DD/YYYY")
                            ) : (
                              "-"
                            )
                          ) : title.includes("Value") ||
                            title.includes("Price") ||
                            title.includes("Cost") ? (
                            `$${format(entry[field])}`
                          ) : (
                            entry[field].toString()
                          )
                        ) : (
                          "-"
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {totalPages && (
        <div className="overflow-x-auto mt-4 mb-2">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex justify-end items-center sm:rounded-lg sm:rounded-bl-none sm:rounded-br-none">
              
              {currentPage === null || currentPage === undefined ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageClassName="font-poppins font-semibold text-white-900 m-1"
                  pageLinkClassName="px-3 text-[18px]"
                  previousClassName="font-poppins font-semibold text-gray-600 m-1 leading-7"
                  previousLinkClassName="px-3 text-[25px] leading-8"
                  nextClassName="font-poppins font-semibold text-gray-600 m-1 leading-7"
                  nextLinkClassName="px-3 text-[25px] leading-8"
                  breakClassName="font-poppins font-semibold text-gray-600 m-1"
                  breakLinkClassName="px-3 text-[18px]"
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={(val) => handlePageChange(val)}
                  containerClassName={
                    "pagination flex self-end py-2 justify-end"
                  }
                  activeClassName={
                    "active !text-[#FFFFFF] bg-ocean-blue rounded"
                  }
                />
              ) : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageClassName="font-poppins font-semibold text-white-900 m-1"
                  pageLinkClassName="px-3 text-[18px]"
                  previousClassName={`font-poppins font-semibold text-gray-600 m-1 leading-7 ${
                    0 == currentPage ? "disabled" : ""
                  }`}
                  previousLinkClassName={`px-3 text-[25px] leading-8 ${
                    0 == currentPage ? "disabled" : ""
                  }`}
                  nextClassName={`font-poppins font-semibold text-gray-600 m-1 leading-7`}
                  nextLinkClassName={`px-3 text-[25px] leading-8 `}
                  breakClassName="font-poppins font-semibold text-gray-600 m-1"
                  breakLinkClassName="px-3 text-[18px]"
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  forcePage={currentPage}
                  onPageChange={(val) => handlePageChange(val)}
                  containerClassName={
                    "pagination flex self-end py-2 justify-end"
                  }
                  activeClassName={
                    "active !text-[#FFFFFF] bg-[#1f3a8a] rounded"
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
