import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase.config";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";

function ReportGrid({ committee, user, district }) {
  const [committeNumber, setCommitteeNumber] = useState();
  const [addressNumber, setAddressNumber] = useState();

  const getUser = async () => {
    const committeeCollectionRef = collection(db, "users");

    const data = query(
      committeeCollectionRef,
      where("district", "==", committee)
    );
    const querySnapshot = await getCountFromServer(data);

    setCommitteeNumber(querySnapshot.data().count);
  };

  const getAddress = async () => {
    const addressCollectionRef = collection(db, committee);
    const data = query(addressCollectionRef);
    const querySnapshot = await getCountFromServer(data);

    setAddressNumber(querySnapshot.data().count);
  };

  const getDistrictAddress = async () => {
    const districtAddressCollectionRef = collection(db, district);
    const data = query(
      districtAddressCollectionRef,
      where("areaCommittee", "==", committee)
    );
    const querySnapshot = await getCountFromServer(data);

    setAddressNumber(querySnapshot.data().count);
  };

  useEffect(() => {
    if (user?.userType === "Super Admin") {
      getUser();
      getAddress();
    } else {
      getDistrictAddress();
    }
  }, []);

  return (
    <>
    
      <div className="w-full md:max-w-[50%] xl:max-w-[25%] pr-5 pb-5">
        <div className="flex flex-col flex-1 justify-between box w-full bg-white h-36 cursor-pointer py-4 px-5">
          <div className="flex flex-col gap-0">
            <h6 className="text-left text-base text-[#464A57] font-semibold mb-0 capitalize">
              {committee}
            </h6>
            {user?.userType === "Admin" && (
              <span className="text-xs text-[#464A57] capitalize">
                Area Committee
              </span>
            )}
            {user?.userType === "Super Admin" && (
              <span className="text-sm block mt-2">
                No of area committee: {committeNumber - 1}
              </span>
            )}
          </div>
          <div className="text-right pr-3 pb-1">
            <h4 className=" text-3xl text-[#464A57] font-semibold mb-0 capitalize">
              {addressNumber}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportGrid;
