import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import { protectedRoutes } from "./protectedRoutes";
import { publicRoutes } from "./publicRoutes";
import Login from "../pages/Login/Login";

export const AppRoutes = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      localStorage.setItem("User", JSON.stringify(currentUser));
    });
  }, []);

  const commonRoutes = [{ path: "/", element: <Login /> }];

  const routes = user?.email ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
