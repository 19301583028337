export const districtCommittee = [
  "Kasaragod",
  "Kannur",
  "Wayanad",
  "Kozhikode",
  "Malappuram",
  "Palakkad",
  "Thrissur",
  "Ernakulam",
  "Idukki",
  "Kottayam",
  "Alappuzha",
  "Pathanamthitta",
  "Kollam",
  "Thiruvananthapuram",
];

export const areaCommittee = {
  Kasaragod: [
    "Thrikkarippur",
    "Cheruvathur",
    "Nileswaram",
    "Eleri",
    "Kanhangad",
    "Panathadi",
    "Bedakam",
    "Uduma",
    "Karadukka",
    "Kasaragod",
    "Kumbala",
    "Manjeswaram",
  ],
  Kannur: [
    "Alakode",
    "Peringome",
    "Payyanur",
    "Madayi",
    "Thaliparamba",
    "Sreekandapuram",
    "Mayyil",
    "Pappinissery",
    "Kannur",
    "Edakkad",
    "Pinarayi",
    "Anjarakkandy",
    "Thalassery",
    "Panoor",
    "Koothuparamba",
    "Mattanur",
    "Iritty",
    "Peravoor",
  ],
  Wayanad: [
    "KALPETTA",
    "SULTHAN BATHERI ",
    "MANANTHAVADI",
    "PANAMARAM",
    "PULPALLY",
    "VYTHIRI",
    "MEENAGADI",
    "KOTTATHARA",
  ],
  Kozhikode: [
    "FAROK",
    "KOZHIKODE SOUTH",
    "KOZHIKODE TOWN",
    "KOZHIKODE NORTH",
    "KUNDAMANGALAM",
    "THAMARASSERY",
    "THIRUVAMBADI",
    "BALLUSSERI",
    "KOYILANDY",
    "PAYYOLI",
    "VADAKARA",
    "ONCHIYAM",
    "NADAPURAM",
    "KUNNUMMAL",
    "PERAMBRA",
    "KAKKODI",
    "Kozhikode medical College"
  ],
  Malappuram: [
    "Edakkara",
    "Nilambur",
    "Wandoor",
    "Areekode",
    "Manjeri",
    "Mankada",
    "Malappuram",
    "Perinthalmanna",
    "Kottakkal",
    "Kondotty",
    "Thirurangady",
    "Tanur",
    "Tirur",
    "Thavanur",
    "Edappal",
    "Vengara",
    "Vallikkunnu",
    "Ponnani",
    "Valanchery",
    "University Campus",
    "AVC",
    "Malayalam university",
    "Karshikam  college",
    "Manjeri medical college",
  ],
  Palakkad: [
    "THRITHALA",
    "PATTAMBI",
    "OTTAPPALAM",
    "CHERPPULASSERY",
    "SREEKRISHNAPURAM",
    "MANNARKKAD",
    "ATTAPPADI",
    "MUNDUR",
    "PALAKKAD",
    "PUTHUSSERY",
    "CHITTUR",
    "KOLLANKODE",
    "KUZHALMANNAM",
    "ALATHUR",
    "VADAKKANCHERY",
  ],
  Thrissur: [
    "CHELAKKARA",
    "WADAKKANCHERY",
    "THRISSUR",
    "PUZHAKKAL",
    "KUNNAMKULAM",
    "CHAVAKKAD",
    "MANALUR",
    "NATTIKA",
    "KODUNGALLUR",
    "IRINJALAKKUDA",
    "MALA",
    "CHALAKKUDY",
    "KODAKARA",
    "MANNUTHI",
    "OLLUR",
    "CHERPPU",
    "Gov. Engineering college Thrissur",
    "Gov. Law college Thrissur ",
    "Vaidhyarathnam ayoorvedha college",
    "Kalamandalam",
    "Vetinary college",
    "Dairy college",
  ],
  Ernakulam: [
    "Ernakulam",
    "Kochi",
    "Palluruthy",
    "Thripunithura",
    "koothattkulam",
    "Muvattupuzha",
    "Kolanchery",
    "Thrikkakkara",
    "Kothamangalam",
    "Kavalangad",
    "Perumbavoor",
    "Aluva",
    "Angamaly",
    "Paravoor",
    "Kalamassery",
    "Vypin",
    "CUSAT",
    "SSUS",
    "KUFOS",
    "Medical College",
  ],
  Idukki: [
    "Marayoor",
    "Munnar",
    "Adimali",
    "Rajakkad",
    "Santhampara",
    "Nedumkandam",
    "Vandanmedu",
    "Peerumedu",
    "Elappara",
    "Kattappana",
    "Idukki",
    "Moolamatam",
    "Thodupuzha west",
    "Thodupuzha east",
    "Karimannoor",
  ],
  Kottayam: [
    "Vaikam",
    "Thalayolaparambu",
    "Kaduthuruthy",
    "Ettumanoor",
    "Pala",
    "Poonjar",
    "Kanjirappally",
    "Vazhoor",
    "Puthuppally",
    "Ayarkunnam",
    "Chenganasery",
    "Kottayam",
    "Mg University",
    "Govt Medical College",
    "Govt Pharmacy college",
    "Leegal thott campus"
  ],
  Alappuzha: [
    "Aroor",
    "Cherthala",
    "Kanjikuzhy",
    "Mararikulam",
    "Alappuzha",
    "Ambalappuzha",
    "Kuttanad",
    "Thakazhy",
    "Harippad",
    "Karthikapally",
    "Kayamkulam",
    "Charumood",
    "Mavelikara",
    "Mannar",
    "Chengannur",
  ],
  Pathanamthitta: [
    "Pathanamthitta",
    "Ranni",
    "Perunad",
    "Kozhencherry",
    "Thiruvalla",
    "Mallapally",
    "Eraviperoor",
    "Pandalam",
    "Adoor",
    "Kodumon",
    "Konni",
  ],
  Kollam: [
    "Kollam",
    "Kollameast",
    "Anchalumoodu",
    "Kundara",
    "Kottiyam",
    "Chathannoor",
    "Kadakkal",
    "Chadayamangalam",
    "Anchal",
    "Kottarakkara",
    "Kunnicodu",
    "Pathanapuram",
    "Punalur",
    "Chavara",
    "Karunagapally",
    "Kunnathoor",
    "Neduvathoor",
    "Shooranad",
  ],
  Thiruvananthapuram: [
    "parassala",
    "neyyattinkara",
    "kattaakkada",
    "vilappil",
    "Chala",
    "kovalam",
    "nemom",
    "palayam",
    "peroorkkada",
    "kazhakkoottam",
    "nedumangad",
    "venjaramood",
    "kilimanoor",
    "attingal",
    "varkkala",
    "mangalapuram",
    "vanjiyoor",
    "vellarada",
    "vithura",
  ],
};
