import React, { useEffect, useState } from "react";
import Logo from "../../assets/studentPortal.svg";
import LetteredAvatar from "react-lettered-avatar";
import {
  LockClosedIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../Firebase/firebase.config";

function Header({ user, setSideMenu }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const logOut = async () => {
    await signOut(auth);
    navigate("/");
  };
  return (
    <>
      <div className="px-4 md:p-4">
        <div className="bg-[#1f3a8a] md:bg-transparent mt-0 h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] md:mt-0 -mx-5 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100   before:absolute before:h-[65px] before:inset-0 before:top-0 before:mx-7 before:bg-[#b2bcd7] before:mt-3 before:rounded-xl before:hidden before:md:block  after:absolute after:inset-0 after:h-[65px] after:mx-3 after:bg-[#1f3a8a] after:mt-5 after:rounded-xl after:shadow-md after:hidden after:md:block">
          <div className="flex h-full items-center justify-between relative z-50">
            <div>
              <img src={Logo} alt="" className="w-44" />
            </div>
            <div className="relative">
              <button onClick={() => setShowDropdown(!showDropdown)}>
                <LetteredAvatar size={35} name={user?.name.charAt(0)} />
              </button>
              {showDropdown && (
                <div className="absolute right-0 mt-5 bg-[#1f3a8a] w-56 rounded-md border-transparent py-5 shadow-[0px_3px_10px_#00000017]w-56  text-white before:absolute before:inset-0 before:z-[-1] before:block before:rounded-md before:bg-[#1f3a8a]">
                  <div className="px-4 flex flex-col">
                    <h6 className="font-medium m-0 capitalize">{user?.name}</h6>
                    {user?.userType === "Super Admin" ? (
                      <span className=" text-xs text-white/70 capitalize">{`${user?.district}`}</span>
                    ) : (
                      <span className=" text-xs text-white/70 capitalize">{`${user?.district} District`}</span>
                    )}
                  </div>
                  <div className="h-px my-4  bg-white/[0.08]"></div>
                  <div className="px-4 flex flex-col">
                    <div
                      onClick={() => {
                        setSideMenu("Reset Password");
                        setShowDropdown(!showDropdown);
                      }}
                      className="flex gap-2 items-center font-normal text-sm my-3 cursor-pointer"
                    >
                      <LockClosedIcon className="h-4 w-4 text-white" />
                      <span>Reset Password</span>
                    </div>
                    <div className="flex gap-2 items-center font-normal text-sm my-3 cursor-pointer">
                      <QuestionMarkCircleIcon className="h-4 w-4 text-white" />
                      <span>Help</span>
                    </div>
                    <div
                      onClick={logOut}
                      className="flex gap-2 items-center font-normal text-sm my-3 mb-0 cursor-pointer"
                    >
                      <LogoutIcon className="h-4 w-4 text-white" />
                      <span>Logout</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
