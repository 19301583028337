import React, { useState } from "react";
import InputBox from "../../componets/InputBox/InputBox";
import Button from "../../componets/Button/Button";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../Firebase/firebase.config";
import { addDoc, collection } from "firebase/firestore";

function Login() {
  const errorCollectionRef = collection(db, 'Error');
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loginType, setLoginType] = useState("Area Login");
  const [credential, setCredential] = useState({
    email: "",
    password: "",
  });
  const [errorField, setErrorField] = useState({
    status: false,
    msg: "",
  });
  const loginTypes = ["State Login", "District Login", "Area Login"];

  const SignInBtnClick = async () => {
    try {
      setLoader(true);
      const user = await signInWithEmailAndPassword(
        auth,
        `${credential?.email}@gmail.com`,
        credential?.password,

      );
      console.log(user);
      if (user?.user?.email) {
        navigate("/home");
        setLoader(false);
      }
      
    } catch (error) {
      console.log("error:::::::", error?.message);
      setLoader(false);
      setErrorField((prevErrorField) => ({
        ...prevErrorField,
        status: true,
        msg: error?.message
          .replace("Firebase: Error (auth/", "")
          .replace(").", "")
          .replace("-", " "),
      }));

      setTimeout(
        () =>
          setErrorField((prevErrorField) => ({
            ...prevErrorField,
            status: false,
            msg: "",
          })),
        5000
      );
      const currentDateTime = new Date().toISOString();
      const errorBody = {
        username: credential?.email,
        password: credential?.password,
        Time: currentDateTime
      }
      addDoc(errorCollectionRef, errorBody)
    }
  };
  return (
    <>
      <div className="relative flex flex-1 flex-col items-center justify-center min-h-screen p-7">
        <h1 className="text-center text-2xl text-[#464A57] font-bold mb-10">
          Login
        </h1>
        <div className="w-full max-w-sm">
          {errorField?.status && (
            <div className="py-3 text-center w-full text-base text-[#f02849] font-medium">
              {errorField?.msg}
            </div>
          )}
          <div className="mb-6">
            <InputBox
              label="Username"
              type="name"
              id="loginEmail"
              value={credential?.email}
              onChange={(e) => {
                setCredential({ ...credential, email: e.target.value });
              }}
            />
          </div>
          <div className="mb-6">
            <InputBox
              label="Password"
              type="password"
              id="password"
              value={credential?.password}
              onChange={(e) =>
                setCredential({ ...credential, password: e.target.value })
              }
            />
          </div>
          <Button
            type="submit"
            loader={loader}
            label="Sign in to account"
            size="sm"
            onClick={SignInBtnClick}
          />
          <div className="hidden flex flex-wrap gap-3 items-center justify-center mt-10">
            {loginTypes?.map((login, index) => (
              <button key={index}
                onClick={() => setLoginType(login)}
                className={`inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 text-slate-900 ring-1 ring-slate-900/10 hover:ring-slate-900/20 ${
                  loginType === login ? "bg-slate-900 text-white" : ""
                }`}
              >
                <span>{login}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
