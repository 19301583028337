import React, { useState } from "react";
import InputBox from "../InputBox/InputBox";
import Button from "../Button/Button";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../../Firebase/firebase.config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { areaCommittee } from "../ViewAddress/CommitteData";

function AddAreaCommittee({ district }) {
  const userInitialState = {
    name: "",
    email: "",
    password: "",
    uid: "",
    areaCommittee: "",
  };
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState(userInitialState);

  const usersCollectionRef = collection(db, "users");

  const Add = async (uid) => {
    const userDetails = {
      district: district,
      name: user?.areaCommittee.toLowerCase(),
      uid: uid,
      userType: "Customer",
    };
    try {
      setLoader(true);
      addDoc(usersCollectionRef, userDetails).then((resp) => {
        if (resp?.id) {
          setLoader(false);
          toast.success(`${user?.areaCommittee} Added Succesfully`);
          setUser(userInitialState);

        }
      });
    } catch (error) {
      setLoader(false);
      console.log("error:", error);
    }
  };

  const CreateUser = async () => {
    setLoader(true);
    const newUser = await createUserWithEmailAndPassword(
      auth,
      `${user?.name}@gmail.com`,
      user?.password
    );
    if (newUser?.user?.uid) {
      Add(newUser?.user?.uid);
    }
  };
  return (
    <>
      <ToastContainer />
      <h1 className="text-left text-2xl text-[#464A57] font-bold mb-10">
        Add Area Committee
      </h1>
      <div className="flex flex-wrap sm:gap-5 items-center">
        <div className="mb-6 w-full md:w-[30%] sm:w-[48%]">
          <label className="block text-sm font-semibold leading-6 text-gray-900">
            Select Area Committee
          </label>
          <select
            value={user?.areaCommittee}
            onChange={(e) => {
              setUser({
                ...user,
                areaCommittee: e.target.value,
              });
            }}
            id="selectAC"
            className="w-full mt-2 sm:max-w-80 appearance-none text-slate-900 bg-white rounded-md px-3 h-10 shadow-sm sm:text-sm focus:outline-none  focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 cursor-pointer"
          >
            <option value="" hidden>
              Select Area Committee
            </option>
            {areaCommittee[district]?.map((ac, index) => (
              <option className="capitalize" key={index} value={ac}>
                {ac.charAt(0).toUpperCase() + ac.slice(1).toLowerCase()}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-6 w-full md:w-[30%] sm:w-[48%]">
          <InputBox
            label="Username"
            type="name"
            id="name"
            value={user?.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          />
        </div>
        <div className="mb-6 w-full md:w-[30%] sm:w-[48%]">
          <InputBox
            label="Password"
            type="password"
            id="password"
            value={user?.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
        </div>
      </div>
      <div className="w-full md:max-w-[30%] sm:w-[48%] sm:px-2 mt-8">
        <Button
          type="submit"
          loader={loader}
          label="Create User"
          size="sm"
          onClick={CreateUser}
        />
      </div>
    </>
  );
}

export default AddAreaCommittee;
