import React, { useEffect, useState } from "react";
import Header from "../../componets/Header/Header";
import Sidebar from "../../componets/Sidebar/Sidebar";
import AddAddress from "../../componets/AddAddress/AddAddress";
import ViewAddress from "../../componets/ViewAddress/ViewAddress";
import ResetPassword from "../../componets/ResetPassword/ResetPassword";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Firebase/firebase.config";
import { Spinner } from "../../componets/Spinner/Spinner";
import GeneralReport from "../../componets/GeneralReport/GeneralReport";
import AddAreaCommittee from "../../componets/AddAreaCommittee/AddAreaCommittee";
import Error from "../../componets/Errors/Error";

function Home() {
  const [sideMenu, setSideMenu] = useState("");
  const [user, setUser] = useState();
  const usersCollectionRef = collection(db, "users");

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("User"));
    getUser(loggedUser?.uid);
  }, []);

  const getUser = async (uid) => {
    const data = query(usersCollectionRef, where("uid", "==", uid));
    const querySnapshot = await getDocs(data);
    setUser(querySnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    if (user && user[0]?.userType === "Customer") {
      setSideMenu("Add Address");
    } else if (user && user[0]?.userType === "Admin" || user && user[0]?.userType === "Super Admin" ) {
      setSideMenu("General Report");
    } 
  }, [user]);

  return (
    <>
      {user ? (
        <div>
          <Header user={user[0]} setSideMenu={setSideMenu} />
          <Sidebar
            user={user[0]}
            sideMenu={sideMenu}
            setSideMenu={setSideMenu}
          />
          <div className="w-full pl-20 pt-6 md:pl-72 sm:pt-6 md:pt-20 pr-7 pb-5">
            {sideMenu === "Add Address" && <AddAddress user={user[0]}/>}
            {sideMenu === "View Address" && <ViewAddress user={user[0]} district={user[0]?.district}/>}
            {sideMenu === "Reset Password" && <ResetPassword />}
            {sideMenu === "General Report" && <GeneralReport user={user[0]} district={user[0]?.district}/>}
            {sideMenu === "Add Area Committee" && <AddAreaCommittee district={user[0]?.district} />}
            {sideMenu === "Errors" && <Error />}

          </div>
        </div>
      ) : (
        <div className="flex flex-1 justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      )}
    </>
  );
}

export default Home;
