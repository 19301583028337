import React from "react";
import {Navigate} from "react-router-dom"
import Login from "../pages/Login/Login";

export const publicRoutes = [
  {
    path: "/login",
    element:  <Login />,
  },
  {path: "*", element: <Navigate to ="/login" />}
];
