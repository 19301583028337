import "./App.css";
import { AppRoutes } from "./Router";

function App() {
  return (
    <>
      <div className="bg">
      {/* <img src={bg} alt="" className="absolute left-0 top-0  w-full max-w-none" /> */}
        <div className="absolute inset-0 text-slate-900/[0.03] ">
          <svg
            className="absolute inset-0 h-full w-full"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <pattern
                id="grid-bg"
                width="32"
                height="32"
                patternUnits="userSpaceOnUse"
                x="100%"
                patternTransform="translate(0 -1)"
              >
                <path d="M0 32V.5H32" fill="none" stroke="currentColor"></path>
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid-bg)"></rect>
          </svg>
        </div>
        <div className="relative">
        <AppRoutes />
        </div>
      </div>
    </>
  );
}

export default App;
