import React, { useEffect, useState } from "react";
import { Table } from "../Table/Table";
import Button from "../Button/Button";
import { TrashIcon, PencilAltIcon } from "@heroicons/react/outline";
import InputBox from "../InputBox/InputBox";
import { areaCommittee, districtCommittee } from "./CommitteData";
import { db } from "../../Firebase/firebase.config";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "../Spinner/Spinner";
function ViewAddress({ user, district }) {
  const addressInitialState = {
    name: "",
    houseName: "",
    streetName: "",
    pincode: "",
    postOffice: "",
    mobileNumber: "",
    areaCommittee: user?.name,
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(addressInitialState);
  const [deleteUser, setDeleteUser] = useState({ id: 0, status: false });
  const [sortField, setSortField] = useState("EndDate");
  const [sortOrder, setSortOrder] = useState("asc");
  const [address, setAddress] = useState();
  const [postOffice, setPostOffice] = useState();
  const [filterInput, setFilterInput] = useState({
    name: "",
    areaCommittee: "",
    district: "Kasaragod",
    status: false,
  });
  const [filterdAddress, setFilterdAddress] = useState();

  useEffect(() => {
    setCurrentPage(0);
    if (
      filterInput?.name?.length > 0 ||
      filterInput?.areaCommittee?.length > 0 ||
      filterInput?.district?.length > 0
    ) {
      setFilterInput({ ...filterInput, status: true });
      const filteredvalue = address?.filter(
        (obj) =>
          obj.name?.toLowerCase().includes(filterInput?.name?.toLowerCase()) &&
          obj.areaCommittee
            ?.toLowerCase()
            .includes(filterInput?.areaCommittee?.toLowerCase())
      );
      setFilterdAddress(filteredvalue);
    } else {
      setFilterInput({ ...filterInput, status: false });
    }
  }, [
    filterInput?.name,
    filterInput?.areaCommittee,
    filterInput?.district,
    address,
  ]);

  // useEffect(() => {
  //   if (filterInput?.areaCommittee?.length > 0) {
  //     const acFilter = (filterInput?.status ? filterdAddress : address)?.filter((obj) =>
  //       obj.areaCommittee.toLowerCase().includes(filterInput?.areaCommittee.toLowerCase())
  //     );
  //     setFilterdAddress(acFilter);
  //     setFilterInput({ ...filterInput, status: true });

  //   } else {
  //     setFilterInput({ ...filterInput, status: false });
  //   }
  // }, [filterInput?.areaCommittee]);

  useEffect(() => {
    if (selectedUser?.pincode?.length === 6) {
      fetchPostOffice(selectedUser?.pincode);
    }
  }, [selectedUser?.pincode]);

  const fetchPostOffice = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      const data = await response.json();
      setPostOffice(data[0]?.PostOffice);
    } catch (error) {
      console.error("Error fetching post office:", error);
    }
  };

  const getData = () => {
    if (user?.userType === "Customer") {
      getAreaData();
    } else if (user?.userType === "Admin") {
      getDistrictData();
    } else {
      getStateData();
    }
  };
  const getAreaData = async () => {
    const addressCollectionRef = collection(db, district);
    const data = query(
      addressCollectionRef,
      where("areaCommittee", "==", user?.name)
    );
    const querySnapshot = await getDocs(data);
    setAddress(
      querySnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const getDistrictData = async () => {
    const addressCollectionRef = collection(db, district);
    const data = query(addressCollectionRef);
    const querySnapshot = await getDocs(data);
    setAddress(
      querySnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const getStateData = async () => {
    const addressCollectionRef = collection(db, filterInput?.district);
    const data = query(addressCollectionRef);
    const querySnapshot = await getDocs(data);
    setAddress(
      querySnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };
  useEffect(() => {
    getData();
  }, [filterInput?.district]);

  const sortContract = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };
  const pageChangeHandler = (val) => {
    setCurrentPage(val?.selected);
  };

  const EditModel = (id) => {
    setEditUser(true);
    const obj = address.find((emp) => emp.id === id);
    setSelectedUser(obj);
  };

  const UpdateUser = async () => {
    setLoader(true);
    if (
      selectedUser?.name &&
      selectedUser?.houseName &&
      selectedUser?.streetName &&
      selectedUser?.pincode &&
      selectedUser?.postOffice &&
      selectedUser?.mobileNumber
    ) {
      const userDocRef = doc(db, district, selectedUser?.id);
      updateDoc(userDocRef, selectedUser).then(() => {
        setLoader(false);
        setEditUser(false);
        getData();
        toast.success("Address Updated Succesfully");
        setSelectedUser(addressInitialState);
      });
    } else {
      toast.error("All fields are mandatory");
    }
  };

  const DeleteModel = (id) => {
    console.log(id);
    setDeleteUser({ ...deleteUser, id: id, status: true });
  };

  const DeleteAddress = async () => {
    setLoader(true);
    await deleteDoc(doc(db, district, deleteUser.id)).then(() => {
      setDeleteUser({ ...deleteUser, id: 0, status: false });
      setLoader(false);
      getData();
      toast.success("Address Deleted Succesfully");
    });
  };

  const exportPdf = async () => {
    const headers = ["SrNo", "Name", "Address", "PostOffice", "Contact"];
    const doc = new jsPDF({
      orientation: "p",
      format: "a4",
    });

    const tableData = (filterInput?.status ? filterdAddress : address)?.map(
      (value, index) => [
        index + 1,
        value?.name,
        `${value?.houseName}, ${value?.streetName}`,
        `${value?.postOffice} PO, ${value?.pincode} Pin`,
        value?.mobileNumber,
      ]
    );

    doc.autoTable({
      head: [headers],
      body: tableData,
      margin: { top: 10 },
      styles: {
        minCellHeight: 10,
        minCellWidth: 10,
        halign: "left",
        valign: "top",
        fontSize: 11,
      },
      columnStyles: {
        0: { cellWidth: 15, halign: "center" },
        1: { cellWidth: 50 },
        2: { cellWidth: 45 },
        3: { cellWidth: 40 },
        4: { cellWidth: 35 },
      },
    });
    doc.save(`${user?.name}`);
  };

  useEffect(() => {
    console.log("address", address?.length);
  }, [address]);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-wrap gap-3 justify-between items-center mb-4">
        <h1 className="text-left text-2xl text-[#464A57] font-bold ">
          View Address
        </h1>
        <div className="w-40">
          <Button
            type="submit"
            label="Export PDF"
            // loader={loader}
            size="sm"
            onClick={exportPdf}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row flex-wrap justify-end items-center gap-5 mb-4">
        {user?.userType === "Super Admin" && (
          <select
            valuue={filterInput?.district}
            onChange={(e) =>
              setFilterInput({
                ...filterInput,
                district: e.target.value,
              })
            }
            id="selectDC"
            className="w-full sm:max-w-80 appearance-none text-slate-900 bg-white rounded-md px-3 h-10 shadow-sm sm:text-sm focus:outline-none  focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 cursor-pointer"
          >
            {districtCommittee?.map((dist, index) => (
              <option key={index} value={dist}>
                {dist}
              </option>
            ))}
          </select>
        )}
        {user?.userType != "Customer" && (
          <select
            value={filterInput?.areaCommittee}
            onChange={(e) => {
              setFilterInput({
                ...filterInput,
                areaCommittee: e.target.value,
              });
            }}
            id="selectAC"
            className="w-full sm:max-w-80 appearance-none text-slate-900 bg-white rounded-md px-3 h-10 shadow-sm sm:text-sm focus:outline-none  focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 cursor-pointer"
          >
            <option value="" hidden>
              Select Area Committee
            </option>
            {(
              areaCommittee[district] || areaCommittee[filterInput?.district]
            )?.map((ac, index) => (
              <option className="capitalize" key={index} value={ac}>
                {ac.charAt(0).toUpperCase() + ac.slice(1).toLowerCase()}
              </option>
            ))}
          </select>
        )}
        <div className="w-full sm:max-w-80 ">
          <input
            value={filterInput?.name}
            onChange={(e) =>
              setFilterInput({ ...filterInput, name: e.target.value })
            }
            type="name"
            id="search"
            className="appearance-none text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200"
            placeholder="Search Name"
          ></input>
        </div>
      </div>

      <div>
        <span className="block mb-5 font-medium text-base">
          Total{" "}
          {(filterInput?.status ? filterdAddress : address)?.length || "0"}{" "}
          Address Found
        </span>
        <Table
        userType={user?.userType}
          data={(filterInput?.status ? filterdAddress : address)?.slice(
            currentPage * 10,
            (currentPage + 1) * 10
          )}
          currentPage={currentPage}
          sort={sortContract}
          sortField={sortField}
          sortOrder={sortOrder}
          totalPages={
            (filterInput?.status ? filterdAddress : address)?.length / 10
          }
          handlePageChange={(val) => pageChangeHandler(val)}
          columns={[
            {
              title: "Sr No",
              field: "slno",
              Cell({ entry: { entryIndex } }) {
                return (
                  <span className="capitalize  text-sm font-medium leading-6 text-gray-900">
                    {entryIndex}
                  </span>
                );
              },
            },
            {
              title: "Name",
              field: "name",
              Cell({ entry: { name } }) {
                return (
                  <span className="capitalize  text-sm font-medium leading-6 text-gray-900">
                    {name}
                  </span>
                );
              },
            },
            {
              title: "Address",
              field: "streetName",
              Cell({ entry: { houseName, streetName } }) {
                return (
                  <>
                    <span className="block capitalize  text-sm font-medium leading-5 text-gray-900">
                      {`${houseName}`} <br />
                      {streetName}
                    </span>
                  </>
                );
              },
            },
            {
              title: "PostOffice",
              field: "postoffice",
              Cell({ entry: { postOffice, pincode } }) {
                return (
                  <>
                    <span className="block capitalize  text-sm font-medium leading-5 text-gray-900">
                      {`${postOffice} PO`} <br />
                      {`${pincode} Pin`} <br />
                    </span>
                  </>
                );
              },
            },
            {
              title: "Mobile Number",
              field: "mobileNumber",
              Cell({ entry: { mobileNumber } }) {
                return (
                  <>
                    <span className="block capitalize  text-sm font-medium leading-5 text-gray-900">
                      {mobileNumber}
                    </span>
                  </>
                );
              },
            },
            {
              title: "Actions",
              field: "Actions",
              Cell({ entry: { id } }) {
                return (
                  <>
                   <div className="flex justify-center gap-2">
                        <PencilAltIcon
                          onClick={() => EditModel(id)}
                          className="h-6 w-6 text-green-600"
                        />
                        <TrashIcon
                          onClick={() => DeleteModel(id)}
                          className="h-6 w-6 text-red-600"
                        />
                      </div>
                  </>
                );
              },
            },
          ]}
        />
      </div>

      {deleteUser?.status && (
        <div className="fixed p-9 left-0 top-0  z-[89] flex justify-center items-center w-full h-screen bg-black/60">
          <div className="relative z-[99] w-full max-w-2xl bg-white py-5 px-3 sm:py-5 sm:px-6 md:py-10 md:px-12  rounded-2xl">
            <h1 className="text-left text-xl md:text-2xl text-[#464A57] font-bold mb-5">
              Conformation
            </h1>
            <div className="h-px my-4  bg-black/[0.08]"></div>
            <div className="flex flex-wrap items-center overflow-y-scroll max-h-[60vh]">
              <p className="text-sm sm:text-base">
                Are you sure to delete this address
              </p>

              <div className="w-full flex gap-3 flex-wrap justify-end px-2 py-3 pt-9">
                <div className="w-full sm:w-40 ">
                  <button
                    className=" w-full text-center rounded-lg text-sm font-semibold py-2.5 px-4 border border-slate-900 text-slate-900"
                    onClick={() =>
                      setDeleteUser({ ...deleteUser, status: false, id: 0 })
                    }
                  >
                    Cancel
                  </button>
                </div>
                <div className="w-full sm:w-40 ">
                  <Button
                    type="submit"
                    loader={loader}
                    label="Delete"
                    size="sm"
                    onClick={DeleteAddress}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {editUser && (
        <div className="fixed p-9 left-0 top-0  z-[89] flex justify-center items-center w-full h-screen bg-black/60">
          <div className="relative z-[99] w-full max-w-2xl bg-white py-5 px-3 sm:py-8 sm:px-6 md:py-10 md:px-12 rounded-2xl">
            <h1 className="text-left text-2xl text-[#464A57] font-bold mb-5">
              Edit Address
            </h1>
            <div className="h-px my-4  bg-black/[0.08]"></div>
            <div className="flex flex-wrap items-center overflow-y-scroll max-h-[60vh]">
              <div className="w-full sm:max-w-[50%] px-1 py-1.5 md:px-2 md:py-3">
                <InputBox
                  value={selectedUser?.name}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      name: e.target.value,
                    });
                  }}
                  label="Name"
                  type="name"
                  id="name"
                />
              </div>

              <div className="w-full  sm:max-w-[50%]  px-1 py-1.5 md:px-2 md:py-3">
                <InputBox
                  value={selectedUser?.houseName}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      houseName: e.target.value,
                    });
                  }}
                  label="House Name"
                  type="name"
                  id="HouseName"
                />
              </div>
              <div className="w-full  sm:max-w-[50%]  px-1 py-1.5 md:px-2 md:py-3">
                <InputBox
                  value={selectedUser?.streetName}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      streetName: e.target.value,
                    });
                  }}
                  label="Street Name"
                  type="name"
                  id="StreetName"
                />
              </div>

              <div className="w-full  sm:max-w-[50%]  px-1 py-1.5 md:px-2 md:py-3">
                <InputBox
                  value={selectedUser?.pincode}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      pincode: e.target.value,
                    });
                  }}
                  label="Pincode"
                  type="number"
                  id="pincode"
                />
              </div>
              <div
                className={`${
                  postOffice?.length > 0 ? "" : "opacity-50"
                } w-full sm:max-w-[50%] px-2`}
              >
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                  Post Office
                </label>
                <select
                  value={selectedUser?.postOffice}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      postOffice: e.target.value,
                    });
                  }}
                  id="postoffice"
                  className={` w-full mt-2 appearance-none text-slate-900 bg-white rounded-md px-3 h-10 shadow-sm sm:text-sm focus:outline-none  focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 cursor-pointer`}
                >
                  <option value="" hidden>
                    Select Postoffice
                  </option>
                  {postOffice?.map((postOfficeName, index) => (
                    <option key={index} value={postOfficeName?.Name}>
                      {postOfficeName?.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full  sm:max-w-[50%]  px-1 py-1.5 md:px-2 md:py-3">
                <InputBox
                  value={selectedUser?.mobileNumber}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      mobileNumber: e.target.value,
                    });
                  }}
                  label="Mobile Number"
                  type="number"
                  id="mobile"
                />
              </div>

              <div className="w-full flex gap-3 justify-end  px-1 py-1.5 md:px-2 md:py-3 pt-9">
                <div className=" ">
                  <button
                    className="w-36  text-center rounded-lg text-sm font-semibold py-2.5 px-4 border border-slate-900 text-slate-900"
                    onClick={() => setEditUser(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div className="w-40">
                  <Button
                    type="submit"
                    loader={loader}
                    label="Submit"
                    size="sm"
                    onClick={UpdateUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewAddress;
