import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase.config";

function Error() {
  const [data, setData] = useState();
  useEffect(() => {
    getErrorData();
  }, []);

  const getErrorData = async () => {
    const errorCollectionRef = collection(db, "Error");
    const data = query(errorCollectionRef);
    const querySnapshot = await getDocs(data);
    setData(querySnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  console.log(data);

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("en-GB"); // 'en-GB' gives 'dd/mm/yyyy' format
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // ensures 12-hour format
    });

    return { date: formattedDate, time: formattedTime };
  };

  return (
    <>
      <div className="flex flex-wrap">
        {data?.map((data) => (
          <div className="w-full md:max-w-[50%] xl:max-w-[25%] pr-5 pb-5">
            <div className="flex flex-col flex-1 justify-between box w-full bg-white cursor-pointer py-4 px-5">
              <div className="flex flex-col gap-0">
                <h6 className="text-left text-base text-[#464A57] font-semibold mb-0 capitalize">
                  User Name: {data?.username}
                </h6>
                <span className="text-sm text-[#464A57] capitalize">
                  Password: {data?.password}
                </span>
                <span className="text-sm text-[#464A57] capitalize">
                 
                  {formatDateTime(data?.Time).date},{" "}
                  {formatDateTime(data?.Time).time}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Error;
