import React from "react";

function InputBox({ label,type,id , ...props}) {
  return (
    <>
      <label className="block text-xs md:text-sm font-semibold leading-6 text-gray-900">
        {label}
      </label>
      <input
        type={type}
        id={id}
        className="mt-1 md:mt-2 appearance-none text-sm md:text-base text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200"
        { ...props}

      ></input>
    </>
  );
}

export default InputBox;
