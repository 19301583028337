import React from "react";
import { Spinner } from "../Spinner/Spinner";

function Button({ type, label,loader, size, ...props }) {
  const sizes = {
    sm: "py-4 px-4 text-base w-60",
  };
  return (
    <button 
      type={type}
      className="inline-flex gap-2 items-center justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 text-white hover:bg-slate-700 w-full"
      { ...props}
    >
        {loader && (
        <Spinner size={size === "sm" ? "sm" : "md"} className="text-current" />
      )}
      <span>{label}</span>
    </button>
  );
}

export default Button;
