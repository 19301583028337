import React from "react";
import ReportGrid from "../ReportGrid/ReportGrid";
import { districtCommittee, areaCommittee } from "../ViewAddress/CommitteData";

function GeneralReport({user, district}) {
  return (
    <>
      <h1 className="text-left text-2xl text-[#464A57] font-bold mb-7">
        General Report
      </h1>

      <div className="flex flex-wrap">
        {user?.userType === "Super Admin" &&  districtCommittee?.map((committee, index) => (
          <ReportGrid key={index} committee={committee} user={user}/>
        ))}
         {user?.userType === "Admin" &&  areaCommittee[district] .map((committee, index) => (
          <ReportGrid key={index} committee={committee.toLowerCase()} user={user} district={district} />
        ))}
      </div>
    </>
  );
}

export default GeneralReport;
