import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
import {getFirestore} from "firebase/firestore"



const firebaseConfig = {
  apiKey: "AIzaSyCtlsWmdoHiBLV8EBEi7zvohwjaWv18mf4",
  authDomain: "address-portal.firebaseapp.com",
  projectId: "address-portal",
  storageBucket: "address-portal.appspot.com",
  messagingSenderId: "387322710966",
  appId: "1:387322710966:web:56622d28aa963c6f76cf17",
  measurementId: "G-JY9PLTE07S"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const auth = getAuth(firebase);
export const db = getFirestore(firebase)