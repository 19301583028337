import React from "react";
import { Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";


export const protectedRoutes = [
  {
    path: "/home",
    element:  <Home />,
  },
  { path: "*", element: <Navigate to="/home" /> },
  { path: "/", element: <Navigate to="/home" /> },
];
