import React, { useEffect, useState } from "react";
import InputBox from "../InputBox/InputBox";
import Button from "../Button/Button";
import { getAuth, updatePassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPassword() {
  const [loader, setLoader] = useState(false);
  const credentialInitialState = {
    newPassword: "",
    conformPassword: "",
  };
  const [credential, setCredential] = useState(credentialInitialState);

  const resetPassword = () => {
    setLoader(true);
    if (
      credential?.newPassword ===
      credential?.conformPassword
    ) {
      const auth = getAuth();
      const user = auth.currentUser;
      updatePassword(user, credential?.newPassword)
        .then((e) => {
          setLoader(false);
          toast.success("Password Changed Successfully");
          setCredential(credentialInitialState)
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Please try again");
          setCredential(credentialInitialState)
        });
    } else {
      setLoader(false);
      toast.error("Passwords do not match");
      setCredential(credentialInitialState)
    }
  };

  return (
    <>
      <ToastContainer />
      <h1 className="text-left text-2xl text-[#464A57] font-bold mb-10">
        Reset Password
      </h1>
      <div className="flex flex-wrap gap-5 items-center">
        <div className=" max-w-full w-full sm:max-w-[47%] md:max-w-[30%] px-2">
          <InputBox
            value={credential?.newPassword}
            onChange={(e) => {
              setCredential({ ...credential, newPassword: e.target.value });
            }}
            label="New Password"
            type="password"
            id="Newpassword"
          />
        </div>
        <div className=" max-w-full w-full  sm:max-w-[47%] md:max-w-[30%] px-2">
          <InputBox
            value={credential?.conformPassword}
            onChange={(e) => {
              setCredential({ ...credential, conformPassword: e.target.value });
            }}
            label="Conform Password"
            type="password"
            id="Conformpassword"
          />
        </div>
      </div>
      <div className=" max-w-full w-full  sm:max-w-[47%] md:max-w-[30%] px-2 mt-8">
        <Button
          type="submit"
          loader={loader}
          label="Reset Password"
          size="sm"
          onClick={resetPassword}
        />
      </div>
    </>
  );
}

export default ResetPassword;
