import {
  NewspaperIcon,
  PlusIcon,
  UserAddIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import React from "react";

function Sidebar({ user, sideMenu, setSideMenu }) {
  const sideMenus = ["Add Address", "View Address"];
  console.log(user?.userType);
  return (
    <>
      <div className=" bg-white z-50 w-[60px] px-2 md:px-4 pb-16 pt-32 md:block md:w-[260px] min-h-screen fixed top-0 left-0 primary-shadow">
        <ul className="p-0 m-0">
          {user?.userType != "Customer" && (
            <li
              onClick={() => setSideMenu("General Report")}
              className={`my-1 sm:h-12 p-2 md:px-4 flex justify-center md:justify-start items-center rounded-xl cursor-pointer hover:bg-[#334d9d] hover:text-white ${
                sideMenu === "General Report"
                  ? "bg-[#334d9d] text-white z-50 border-shadow"
                  : ""
              }`}
            >
              <NewspaperIcon className={`w-6 h-6 md:hidden`} />
              <span className="hidden md:block">General Report</span>
            </li>
          )}
          {user?.userType === "Customer" && <li
              onClick={() => setSideMenu("Add Address")}
              className={`my-1 sm:h-12 p-2 md:px-4 flex justify-center md:justify-start items-center rounded-xl cursor-pointer hover:bg-[#334d9d] hover:text-white ${
                sideMenu === "Add Address"
                  ? "bg-[#334d9d] text-white z-50 border-shadow"
                  : ""
              }`}
            >
              <UserAddIcon className={`w-6 h-6 md:hidden`} />
              <span className="hidden md:block">Add Address</span>
            </li>}
          <li
            onClick={() => setSideMenu("View Address")}
            className={`my-1 sm:h-12 p-2 md:px-4 flex justify-center md:justify-start items-center rounded-xl cursor-pointer hover:bg-[#334d9d] hover:text-white ${
              sideMenu === "View Address"
                ? "bg-[#334d9d] text-white z-50 border-shadow"
                : ""
            }`}
          >
            <ViewListIcon className={`w-6 h-6 md:hidden`} />
            <span className="hidden md:block">View Address</span>
          </li>
          {user?.userType === "Admin" && (
            <li
              onClick={() => setSideMenu("Add Area Committee")}
              className={`my-1 sm:h-12 p-2 md:px-4 flex justify-center md:justify-start items-center rounded-xl cursor-pointer hover:bg-[#334d9d] hover:text-white ${
                sideMenu === "Add Area Committee"
                  ? "bg-[#334d9d] text-white z-50 border-shadow"
                  : ""
              }`}
            >
              <PlusIcon className={`w-6 h-6 md:hidden`} />
              <span className="hidden md:block">Add Area Committee</span>
            </li>
          )}
          {user?.userType === "Super Admin" && (
            <li
              onClick={() => setSideMenu("Errors")}
              className={`my-1 sm:h-12 p-2 md:px-4 flex justify-center md:justify-start items-center rounded-xl cursor-pointer hover:bg-[#334d9d] hover:text-white ${
                sideMenu === "Errors"
                  ? "bg-[#334d9d] text-white z-50 border-shadow"
                  : ""
              }`}
            >
              <PlusIcon className={`w-6 h-6 md:hidden`} />
              <span className="hidden md:block">Errors</span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
